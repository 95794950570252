.blog {
	margin-top: 150px;
	margin-bottom: 35px;

	.full-img {
		width: 100%;
	}

	h1 {
		font-size: 36px;
		line-height: 46px;
		color: var(--blue);
		font-weight: 600;
	}

	h2 {
		font-weight: 600;
		font-size: 26px;
		line-height: 36px;
		color: var(--blue);
	}

	@media screen and (max-width: 989px) {
		h1 {
			font-size: 32px;
			line-height: 40px;
		}

		h2 {
			font-size: 28px;
			line-height: 40px;
		}
	}

	.blog-entry {
		color: #333;
		display: block;
		text-decoration: none;
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px dotted #7c949f;

		.blog-header {
			margin-bottom: 10px;
		}

		h2 {
			font-size: 24px;
			margin-top: 0;
		}




	}

}

.blogentry {
	.tag-box {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 5px 0;

			a {
				background-color: #ddd;
				padding: 5px;
				display: block;

			}
		}
	}
}