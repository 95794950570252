@import "common.css";

.unit-view {

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin-top: 20px;
	}

	.search-section {
		.searchbox {
			margin: 0 0 10px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}



	.title-img {

		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;

		max-width: 1200px;

		h2 {
			margin-top: 40px;
			font-size: 22px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 15px 15px 5px 15px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
					color: var(--orange);
				}

				.fa-stair {
					background: url(RESOURCE/img/stairs.png) no-repeat left center;
					width: 32px;
					height: 32px;
					display: block;
					background-size: 32px 32px;
					margin: 0 auto;
				}

				.crossed-property {
					.fas {
						position: relative;
						color: var(--orange);

						&:after {
							content: "";
							background: #222222;
							height: 2px;
							top: 50%;
							left: -5px;
							right: -5px;
							position: absolute;
							transform: rotate(-45deg);
						}
					}

					div {
						/*text-decoration: line-through;*/
					}
				}

				>div {
					text-align: center;
				}
			}

			.properties {

				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.properties {
				padding: 0px;

				.prop {
					background: url(RESOURCE/img/list-arrow.png) no-repeat left center;
					padding-left: 16px;
					margin: 5px 0px;
					font-size: 15px;
					line-height: 24px;
					position: relative;

					&.missing {
						opacity: 0.6;
					}
				}

			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}


		@media (min-width:768px) {
			.info-col.noaction {
				position: absolute;
				right: 0;
				top: -41px;

				width: 33.33333333%;
				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

				.info-col-header {
					height: 40px;
					padding: 1px;
					background-color: rgba(60, 63, 64, 0.9);
					color: #fff;
					text-align: center;

					h2 {
						margin-top: 10px;
						font-size: 18px;
					}
				}

				.info-col-main {
					border: 1px solid #888;
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}

		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.subrow {
					@media all and (max-width:479px) {
						.btn.btn-default {
							padding: 10px 10px;
						}

					}
				}

				.flow-buttons {
					button {
						margin-left: 20px;

						@media all and (max-width:479px) {
							width: 100%;
							margin: 0px 0px 10px 0px;
						}
					}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}

	.contact-form {
		.checkbox label:before {
			top: 5px;
		}
	}

	@media (min-width:768px) {
		.contact-form {
			h2 {
				height: 40px;
				padding-top: 9px;
				background-color: rgba(60, 63, 64, .9);
				color: #fff;
				text-align: center;

				font-size: 18px;
				margin: -10px;
				width: auto;
				margin-bottom: 16px;

			}

			margin-bottom: 60px;
			border: 1px solid #888;
			margin: 40px 0;
			padding: 10px;
		}
	}

	.subsection {
		width: 100%;

		.unit-map {
			height: 400px;
			width: 100%;


			>div {
				height: calc(100% - 80px);

				@media (max-width:767px) {
					height: calc(100% - 105px);
				}

				@media (max-width:400px) {
					height: calc(100% - 155px);
				}
			}

			&.no-map {

				>div {
					height: 100%;
				}
			}

			.mapnote {
				font-size: 16px;
				padding: 15px;
				background: #fff;

				@media (max-width:992px) {
					font-size: 14px;
				}
			}

			.mapconsent {
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				text-align: center;
				align-items: center;
				justify-content: center;
				padding: 15px;
				background: rgba(26, 121, 187, .15);

				@media (max-width: 992px) {
					padding: 15px;
				}

				@media all and (min-width: 993px) and (max-width: 1199px) {
					padding: 15px;
				}

				a {
					background: #ff9c3c;
					background: var(--blue);
					color: #fff;
					border-radius: 5px;
					padding: 0 15px;
					height: 36px;
					display: block;
					line-height: 35px;

					&:hover {
						background: var(--orange);
					}
				}
			}
		}
	}

	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header h1 {
			font-size: 24px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}