@import "setup.css";

.indexpage {

	.form-flex-bl {
		position: relative;
		padding: 5px;

		.form-control {
			line-height: 24px;
			border-radius: 0;
			background-color: #fff;
		}

		.fa {
			position: absolute;
			right: 17px;
			top: 18px;
			font-size: 20px;
			opacity: .7;
		}
	}

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.iheader {
		height: 100vh;
		min-height: 500px;
		/*background: url(../img/banner2.jpg) no-repeat center;*/
		background: url(RESOURCE/img/home-section-img-1.jpg) no-repeat center;
		background-size: cover;
		background-attachment: fixed;
		position: relative;
		z-index: 12;

		@media (min-width:1200px) {
			min-height: 700px;
		}

		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1,
				h2 {
					text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
				}

				h1 {

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 60px 0 0;
					text-shadow: 0 0 10px #000;

				}

				h2 {
					font-size: 24px;

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 20px 0 0;
					text-shadow: 0 0 10px #000;

				}

			}

		}

		@media screen and (max-width: 767px) {
			float: left;
			width: 100%;
			padding: 120px 0px 60px 0px;
			height: auto;
		}

		@media screen and (max-width: 480px) {}


	}

	.bannerLogo {
		position: absolute;
		top: 125px;
		left: 0px;
		right: 0px;
		text-align: center;

		img {
			max-height: 250px;
		}

		@media screen and (max-width: 812px) {

			img {
				max-height: 200px;
			}
		}

		@media screen and (max-width: 767px) {
			position: relative;
			top: 0px;

			img {
				max-width: 90%;
			}
		}
	}

	.bannerSearch {
		position: absolute;
		top: 60%;
		left: 0px;
		right: 0px;
		z-index: 11;

		.searchbar {

			margin-top: 20px;
			background: none repeat scroll 0 0 rgba(0, 0, 0, 0.4);
			padding: 15px 25px;

			.searchbox {
				padding: 5px;

				input,
				.search-btn,
				select {
					border-radius: 0;
				}

				.form-control {
					box-shadow: none;
					border: none;
					font-family: 15px;
					line-height: 24px;
				}

				.search-popup-trigger {
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					position: relative;
					background: url(RESOURCE/img/dropdown-icon.png) no-repeat center right 12px;

				}

				.search-btn {
					width: 100%;
					background: var(--orange);
					border: none;
					box-shadow: none;
					font-family: 15px;
					line-height: 24px;

					&:hover {
						background: var(--blue);
					}
				}

				.search-popup-trigger {
					cursor: pointer;
				}

				.form-control:disabled,
				.form-control[readonly] {
					background-color: white;
				}
			}

			@media (min-width:768px) {
				max-width: 780px;
			}

			@media (min-width:993px) {
				max-width: 950px;
			}

		}

		@media screen and (max-width: 767px) {
			left: 0px;
			right: 0px;
			position: relative;
			float: left;
			width: 100%;
			padding: 0px 20px;

		}

		@media screen and (max-width: 480px) {}
	}




	/*
	.searchbar {

		margin-top: 20px;
		background: none repeat scroll 0 0 rgba(0, 0, 0, 0.6);
		padding: 15px 25px;

		.searchbox {
			padding: 5px;

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}

		@media (min-width:768px) {
			max-width: 650px;
		}

		@media (min-width:993px) {
			max-width: 950px;
		}

	}

	.contact-section {
		background-color: #fff !important;
	}
*/








}