.booking-view {
	padding: 160px 0px 40px 0px;

	.page-header {
		margin-top: 0px;

		h1 {
			margin-top: 0px;
		}

		.fa-check {
			color: var(--blue);
		}
	}

	.voucher-bl {
		.panel-title {
			font-size: 16px;
			font-weight: 700;

		}

		.pl-0 {
			@media (min-width:768px) {
				padding-left: 7px;
			}
		}

		.input-check-bl {
			@media (min-width:768px) {
				display: flex;
				justify-content: space-between;
			}

			.form-control {
				@media (min-width:768px) {
					margin-right: 10px;
				}

				@media (max-width:767px) {
					margin-bottom: 10px;
				}

				height: 43px;
			}
		}
	}

	.cart-details {

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}

		.price-table {
			.btn {
				display: none;
			}
		}
	}

	.pd-form {
		label {
			font-weight: 700;
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 700;
			}

			.checkbox label:before {
				top: 5px;
			}

			.checkbox-success input[type=checkbox]:checked+label:before {
				background: var(--blue);
				border: solid 1px var(--blue);
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
		margin-top: 15px;

		@media all and (max-width:479px) {
			width: 100%;
			margin: 0px 0px 10px 0px;
			padding: 10px 30px;
		}
	}


}