.indexpage {

	.homeSections {
		float: left;
		width: 100%;
		background: #ffffff;
		font-size: 20px;
		line-height: 30px;
		color: #232323;
		padding: 120px 0px;
		text-align: center;
		position: relative;
		font-weight: 400;

		.container {
			position: relative;
			z-index: 11;
		}

		h3 {
			font-size: 42px;
			line-height: 50px;
			font-weight: 600;
			color: #111111;
			margin: 0px 0px 20px 0px;
		}

		p {
			margin: 20px 0px;
		}

		&.homeSections2 {
			background: url(../img/home-section-img-1.jpg) no-repeat center;
			background-attachment: fixed;
			background-size: cover;

			&:before {
				content: "";
				background: rgba(255, 255, 255, 0.7);
				position: absolute;
				left: 0px;
				right: 0px;
				top: 0px;
				bottom: 0px;

			}
		}

		/*&.homeSections3 {
			background: var(--blue);
			color: #ffffff;

			h3 {
				color: #ffffff;
			}
		}*/

		&.homeSections3 {
			background: url(../img/background_berge.jpg) no-repeat center;
			background-attachment: fixed;
			background-size: cover;

			&:before {
				content: "";
				background: rgba(255, 255, 255, 0.7);
				position: absolute;
				left: 0px;
				right: 0px;
				top: 0px;
				bottom: 0px;

			}
		}

		&.homeSections4 {
			/*background: url(../img/home-section-img-2.jpg) no-repeat center;*/
			background: url(../img/box4.jpg) no-repeat center;
			background-attachment: fixed;
			background-size: cover;

			&:before {
				content: "";
				background: rgba(255, 255, 255, 0.7);
				position: absolute;
				left: 0px;
				right: 0px;
				top: 0px;
				bottom: 0px;

			}
		}

		@media screen and (max-width: 639px) {
			h3 {
				font-size: 36px;
				line-height: 46px;
			}
		}

		@media screen and (max-width: 479px) {
			font-size: 18px;
			line-height: 28px;

			h3 {
				font-size: 28px;
				line-height: 40px;
			}
		}
	}

	.brief {
		padding-top: 80px;
		padding-bottom: 115px;
		font-family: 'Open Sans', Helvetica Neue, Sans-serif;
		line-height: 28px;
		font-size: 16px;


		.content-section {
			margin-top: 15px;
		}

		h2 {
			margin-bottom: 15px;
			margin-top: 10px;
		}

		p {
			margin-top: 40px;
			color: #555;
		}

		ul {
			color: #555;

			.fa {
				padding-right: 10px;
			}

			a {
				color: inherit;
			}
		}

		.brief-image-right {
			margin-top: 20px;
		}

		.brief-image-right img {
			float: right;
			width: 92%;
		}

		.brief-image-left {
			margin-top: 25px;
			overflow: hidden;
		}

		.brief-image-left img {
			float: left;
			width: 95%;
		}


	}

	.grey-bg {
		border-top: 1px solid #eaebec;
		border-bottom: 1px solid #eaebec;
		background: #F7F8FA;
	}


	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
	}

	.uppercase {
		text-transform: uppercase;
	}

	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}


	}

}