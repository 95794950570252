/*.staticBanner {
	background: url(RESOURCE/img/static-banner.jpg) no-repeat center;
	background-size: cover;
	height: 350px;
}*/

.notFound {
	min-height: 600px;
}

.contactBanner {
	background: url(RESOURCE/img/home-section-img-2.jpg) no-repeat center top;
	background-size: cover;
	min-height: 450px;
}

.staticPages {
	padding: 45px 0px;
	font-size: 15px;
	line-height: 26px;

	.page-header {
		margin: 0px 0px 20px 0px;
		position: relative;
		border-bottom: 1px solid #dddddd;
	}

	h1 {
		font-size: 36px;
		line-height: 46px;
		color: var(--blue);
		font-weight: 600;
	}

	h2 {
		font-weight: 600;
		font-size: 26px;
		line-height: 36px;
		color: var(--blue);
	}

	@media screen and (max-width: 989px) {
		h1 {
			font-size: 32px;
			line-height: 40px;
		}

		h2 {
			font-size: 28px;
			line-height: 40px;
		}
	}

	@media screen and (max-width: 767px) {
		h1 {
			font-size: 24px;
			line-height: 32px;
		}

		h2 {
			font-size: 22px;
			line-height: 30px;
		}
	}

	.subTxt {
		font-size: 20px;
	}

	.wirSind {
		padding: 0px 20%;
		text-align: left;

		.col-sm-6 {
			padding: 0px;
		}
	}

	.officeImg {
		img {
			max-width: 100%;
			height: auto;
		}
	}

	.contactAddress {
		font-size: 16px;
		line-height: 26px;
		color: #222222;

		a {
			color: #222222;
			text-decoration: none;

			&:hover {
				color: var(--orange)
			}
		}

		img {
			width: 100%;
			max-width: 400px;
		}

		h6 {
			font-size: 22px;
			line-height: 30px;
			color: var(--blue);
			font-weight: 600;
			margin: 15px 0px;
		}
	}

	.contact-form {
		margin: 20px 0px;

		.form-group {
			label {
				text-align: left;
				display: block;
				font-weight: 600;
				font-size: 14px;
				color: #222222;
				margin: 0px 0px 0px 0px;

				span {
					font-size: 16px;
					color: #ff0000;
					font-weight: 400;
				}
			}

			.form-control {
				border-radius: 0px;
				padding: 8px 12px;
				height: auto;
			}

			.checkbox label:before {
				top: 5px;
			}

			.checkbox {
				label {
					display: inline-block;
				}
			}
		}

		.btn.btn-go {

			padding: 12px 15px 12px 30px;
			border-radius: 0px;
			font-weight: 700;

			.fas {
				margin-left: 20px;
			}
		}
	}

	.button {
		background: #008a97;
		padding: 10px 60px;
		font-size: 18px;
		font-weight: 600;
		color: #ffffff;
		line-height: 30px;
		text-decoration: none;
		margin-top: 20px;
		display: inline-block;
	}


	@media screen and (max-width: 1200px) {
		.rentalLeft {
			iframe {
				height: 300px;
			}

			h2 {
				font-size: 24px;
			}
		}

		.rentalRight {
			img {
				height: 300px;
				width: auto;
			}

			h2 {
				font-size: 24px;
			}
		}
	}

	@media screen and (max-width: 989px) {
		.rentalLeft {
			iframe {
				height: 250px;
			}

			h2 {
				font-size: 18px;
				line-height: 30px;
			}
		}

		.rentalRight {
			img {
				height: 250px;
			}

			h2 {
				font-size: 18px;
				line-height: 30px;
			}
		}
	}

	@media screen and (max-width: 767px) {
		.rentalRight {
			text-align: left;

			.wirSind {
				padding: 0px;

				.col-sm-6 {
					float: left;
					width: 30%;
				}
			}
		}
	}

	@media screen and (max-width: 639px) {
		.rentalRight {

			.wirSind {
				.col-sm-6 {
					width: 50%;
				}
			}
		}
	}

}