@import "setup.css";

.vo-nav-bar {
	position: fixed;
	background: rgba(255, 255, 255, 0.9);
	width: 100%;
	top: 0px;
	z-index: 99;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
	border-bottom: 2px solid var(--orange);

	&.opened {
		.mainHeader {
			.topNav {
				.navbar-collapse {
					display: block;
				}
			}
		}
	}

	.mainHeader {

		&.affix {
			width: 100%;
			background: #ffffff;
			box-shadow: 0 0 8px 0 rgba(0, 0, 0, .3);

			/*.logo {
				padding: 5px 20px 5px 20px;
				border-radius: 0px 0px 0px 0px;
				box-shadow: none;

				img {
					height: 56px;
				}
			}*/
		}

		.logo {
			position: absolute;
			top: 0px;
			background: #fff;
			padding: 15px;
			/*border-radius: 0px 0px 40px 0px;*/
			box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);

			img {
				max-height: 100px;
			}
		}

		.topNav {
			.navbar-toggler {
				background: var(--blue);
				font-size: 22px;
				color: #ffffff;
				padding: 5px 12px;
				border: none;
				box-shadow: none;
				display: none;
			}

			.navbar-right {
				margin: 20px 0px;
				min-height: 20px;
				border: none;
			}

			ul {
				margin: 0px 20px 0px 0px;
				padding: 0px;


				li {
					list-style: none;
					margin: 0px;
					padding: 0px;
					margin: 0px 20px;

					a {
						font-size: 18px;
						color: var(--blue);
						padding: 5px;
						background: transparent;
						text-align: left;
						text-decoration: none;

						&:hover {
							color: var(--orange);
						}
					}

					&:hover {
						.dropdown-menu {
							display: block;
						}
					}

					.dropdown-menu {
						min-width: 200px;
						left: 0px;
						margin: 0px;
						padding: 10px 0px;

						li {
							float: left;
							width: 100%;
							margin: 0px;
							padding: 0px 15px;
						}

						a {
							font-size: 16px;
						}
					}
				}
			}

			@media screen and (max-width: 1200px) {
				ul {
					margin-right: -15px;

					li {
						margin: 0px 10px;

						a {
							font-size: 16px;
							padding: 5px 0px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 989px) {
			.logo {
				img {
					max-height: 100px;
				}
			}

			.topNav {
				ul {
					li {
						margin: 0px 5px;
					}
				}
			}
		}

		@media screen and (max-width: 767px) {
			.logo {
				a {
					padding: 0px;
				}
			}

			.topNav {
				.navbar-toggler {
					display: block;
					position: relative;
					float: right;
					top: 0;
					right: 20px;
				}

				.navbar-right {
					border: none;
				}

				.navbar-collapse {
					position: absolute;
					left: 0px;
					right: 0px;
					top: 100%;
					background: var(--blue);
					padding: 0px;
					display: none;

					ul {
						margin: 0px;
						float: left;
						width: 100%;
						padding: 10px 15px;

						li {
							float: left;
							width: 100%;
							margin: 5px 0px;

							a {
								color: #ffffff;
							}

							&:hover {
								a {
									color: #ffffff;
								}

								.dropdown-menu {
									display: none;
								}
							}

							&.open {
								.dropdown-menu {
									display: block;
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 479px) {
			.logo {
				padding: 10px;

				img {
					max-height: 85px;
				}
			}
		}
	}
}