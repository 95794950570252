.main-footer {

	.mainFooter {
		float: left;
		width: 100%;
		background: var(--blue);
		padding: 45px 0px;
		font-size: 16px;
		line-height: 26px;
		color: #ffffff;

		h6 {
			font-size: 22px;
			line-height: 30px;
			color: #ffffff;
			margin: 0px 0px 15px 0px;
		}

		a {
			color: #ffffff;
			text-decoration: none;

			&:hover {
				color: var(--orange);
			}
		}

		ul {
			margin: 0px;
			padding: 0px;

			li {
				margin: 3px 0px;
				padding: 0px;
				list-style: none;

				.fas {
					margin-right: 8px;
				}
			}
		}

		.box {
			color: #000;
		}

		.footerLogo {
			img {
				max-width: 100%;
				height: auto;
			}

			a {
				background: #ffffff;
				padding: 15px;
				display: block;
			}
		}

		.footerContact {
			.fas {
				margin-right: 8px;
			}
		}

		.vOfficeLogo {
			text-align: right;

			img {
				display: inline-block;
				max-height: 40px;
				margin-top: 40px;
			}
		}
	}

	@media screen and (max-width: 989px) {
		.mainFooter {
			.footerContact {
				float: left;
				width: 100%;
				margin-top: 30px;
			}
		}
	}

	@media screen and (max-width: 767px) {
		.mainFooter {
			.footerLinks {
				float: left;
				width: 50%;
			}
		}
	}

	@media screen and (max-width: 479px) {
		.mainFooter {
			.footerLinks {
				width: 100%;
				margin-bottom: 30px;
			}

			.footerContact {
				margin-top: 0px;
			}
		}
	}



	.contact-section {
		padding: 40px 10px 80px 10px;
		text-align: center;

		p {
			color: #555;
			margin-bottom: 20px;
		}

		p.lead {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.contact {
			font-size: 24px;

			.fa {
				margin: 10px;
				font-size: 36px;
			}



			color: @introcolor;
		}
	}


	footer {
		width: 100%;
		padding: 20px;
		text-align: center;

		.company-name,
		.footer-link {
			font-family: "Raleway", sans-serif;

			font-size: 18px;

			background-color: @navbgcolor;

			color: #888;


		}


		.footer-link {
			color: #888;
		}


	}
}