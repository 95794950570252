@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.container-fluid {
		max-width: 1440px;
		width: 100%;
	}



	.searchBanner {
		/*background: url(RESOURCE/img/home-section-img-1.jpg) no-repeat center;*/
		background: url(RESOURCE/img/search-banner.jpg) no-repeat center;
		background-size: cover;
		padding: 150px 0px 75px 0px;

		.show-map-list {
			float: right;

			a {
				display: inline-block;
				background: #ffffff;
				border: solid 2px #ffffff;
				padding: 10px 45px;
				text-transform: uppercase;
				font-size: 18px;
				color: var(--blue);
				border-radius: 0px;
				margin: 8px 0px;
				text-decoration: none
			}
		}

		.form-control {
			background: rgba(255, 255, 255, 0.9);
			border-radius: 0px;
			padding: 10px 50px 10px 15px;
			border: solid 1px var(--blue);
			font-size: 16px;
			line-height: 24px;
			color: var(--blue);
			margin: 8px 0px;
			position: relative;
			height: 44px;

			&.datePickerField,
			&.guestField,
			&.regionField,
			&.bedroomField {
				background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/dropdown-icon.png) no-repeat center right 15px;
				padding: 10px 50px 10px 15px;
			}
		}


		select.form-control {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			position: relative;
			color: var(--blue);
			background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/dropdown-icon.png) no-repeat center right 15px;
		}

		.checkbox {
			background: rgba(255, 255, 255, 0.9);
			padding: 8px;

			label {
				padding-left: 32px;

				&:before {
					margin-left: 0px;
					top: 4px;
					left: 5px;
					border: solid 1px var(--blue);
				}
			}

			input[type=checkbox]:checked+label:before {
				background: var(--blue);
				border: solid 1px var(--blue);
			}

			input[type=checkbox]:checked+label:after {
				margin-left: 0px;
				left: 5px;
				top: -1px;
			}
		}

		.searchBtn {
			.btn {
				display: inline-block;
				background: var(--blue);
				border: solid 2px #ffffff;
				padding: 10px 60px;
				text-transform: uppercase;
				font-size: 18px;
				color: #ffffff;
				border-radius: 0px;
				margin: 8px 0px;
			}
		}

		.adv-filt-form {
			background: rgba(255, 255, 255, 0.8);
			padding: 15px 20px;
			margin-top: 10px;

			.form-control {
				border: 1px solid var(--blue);
				padding: 10px 15px;
				font-size: 15px;
				color: var(--blue);
				border-radius: 0px;
				line-height: 24px;
				margin: 8px 0px;
				height: auto;
			}

			.form-group {
				margin: 0px;

				.checkbox,
				.radio {
					margin-top: 5px;
					margin-bottom: 5px;
				}
			}
		}

		@media screen and (max-width: 1500px) {
			padding-top: 200px;
		}

		@media screen and (max-width: 1200px) {
			padding-top: 200px;
		}

		@media screen and (max-width: 767px) {
			.asd__wrapper--full-screen {
				top: 120px;
				overflow: auto;
				z-index: 12345;
			}
		}

		@media screen and (max-width: 639px) {
			padding: 150px 10px 45px 10px;

			.col-xs-6,
			.col-xs-12 {
				padding: 0px 8px;
			}

			.form-control {
				font-size: 15px;
			}

			.searchBtn {
				.btn {
					width: 100%;
					font-size: 16px;
				}
			}

			.show-map-list {
				width: 100%;
				text-align: center;

				a {
					width: 100%;
					font-size: 16px;
				}
			}
		}

		@media screen and (max-width: 639px) {

			.col-xs-6,
			.col-xs-12 {
				width: 100%;
			}
		}

		@media screen and (max-width: 480px) {
			.box.full-xxs {
				top: 120px !important;
			}
		}
	}

	.results.resultLists {
		background: #ffffff;
		margin: 0px -15px;

		.srow {
			margin-top: 0px;
			margin-bottom: 0px;

			a {
				text-decoration: none;
			}

			.info-col {
				padding: 15px;
				border: solid 1px #e2e2e2;
				border-left: none;

				.offer-bl-srch {
					.offer-box {
						.popover-content {
							ul {
								padding-left: 15px;
							}
						}

						a {
							text-decoration: none;
							background-color: var(--orange);
							color: #fff;
							padding: 10px 10px;
							border: 2px dashed #fff;
							font-size: 13px;
						}
					}
				}

				.map-marker {
					.fas {
						color: var(--blue);
					}
				}

				h4 {
					font-size: 22px;
					line-height: 30px;
					color: var(--blue);
					font-weight: 700;
					padding-right: 0px;
					min-height: 60px;
					white-space: inherit;
				}

				h6 {
					font-size: 16px;
					line-height: 24px;
					margin: 0px 0px 15px 0px;
				}

				.prices {

					display: inline-block;
					width: 100%;
				}

				.alternativesPrice {
					float: left;
					width: 100%;
					margin: 0px 0px 15px 0px;
					font-size: 15px;
					line-height: 22px;

					h5 {
						margin: 0px 0px 10px 0px;
					}

					a {
						color: var(--blue);
						text-decoration: underline;

						&:hover {
							text-decoration: none;
						}

						.special {
							color: var(--blue);
						}
					}
				}

				.rentRate {
					float: left;
					width: 100%;
					font-size: 15px;
					color: #222222;

					strong {
						font-size: 18px;

					}
				}

				.search-result-properties {
					width: 100%;
					margin: 0px 0px 15px 0px;
					padding: 0px 0px;

					.propertiesList {
						float: left;
						font-size: 15px;
						padding: 0px 12px;

						&:first-child {
							padding-left: 0px;
						}

						.fa-stair {
							background: url(RESOURCE/img/stairs.png) no-repeat left center;
							width: 26px;
							height: 26px;
							display: block;
							background-size: 26px 26px;
							margin: 0 auto;
						}

						&.crossed-property {
							.fas {
								position: relative;

								&:after {
									content: "";
									background: #222222;
									height: 2px;
									top: 50%;
									left: -5px;
									right: -5px;
									position: absolute;
									transform: rotate(-45deg);
								}
							}
						}

						div {
							float: left;
						}

						.fas {
							margin-right: 8px;
							font-size: 18px;
							color: var(--orange);
						}
					}
				}
			}
		}

		@media screen and (max-width: 479px) {
			.srow {
				.info-col {
					h4 {
						font-size: 18px;
						font-size: 28px;
					}

					.search-result-properties {
						.propertiesList {
							padding: 0 8px;
						}
					}
				}

			}
		}
	}

	.list-view {
		padding-top: 20px;
		padding-bottom: 20px;

		.map-col {
			.affix {
				@media (min-width:993px) {
					position: fixed !important;
					top: 40px;
					max-width: 480px;
				}
			}
		}
	}

	.map-view {
		.map-col {
			margin-top: 20px;
			margin-bottom: 20px;

			.affix,
			.affix-bottom {
				@media (min-width:993px) {
					position: relative !important;
					top: 0 !important;
					width: 100%;
					padding-right: 0;
					max-width: inherit;
				}
			}
		}
	}


	.map-col {
		.affix {
			@media (max-width:992px) {
				position: relative !important;
			}

			@media (min-width:993px) {
				width: 33.33%;
				max-width: inherit;
				padding-right: 30px;
				top: 60px;
			}

			@media (min-width:1440px) {
				position: fixed !important;
				top: 50px;
				width: 100%;
				max-width: 450px;
			}

		}

		.affix-bottom {
			@media (max-width:992px) {
				position: relative;
				top: inherit !important;
			}
		}
	}

	.result-pane {
		width: 100%;
		padding: 0px;
		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0px 0px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;

			li {

				a,
				span {
					border-radius: 0px !important;
				}
			}

			.active {
				a {
					background: var(--blue);
					color: #ffffff;
					border: solid 1px var(--blue);
					font-weight: 600;
				}
			}
		}

	}

	.map-view {
		.result-pane {
			@media (min-width:993px) {
				display: none;
			}
		}

		.list-content {
			@media (min-width:993px) {
				display: none;
			}
		}

		.map-col {
			width: 100%;
		}
	}

	.map-pane {
		padding: 25px 0;

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		a {
			text-decoration: none;
		}

		h4 {
			font-size: 16px;
			line-height: 22px;
			color: var(--blue);
			font-weight: 700;
		}

		h6 {
			font-size: 14px;
			line-height: 20px;
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel,
	.more-btn-panel {
		background-color: #f5f5f5;
	}

	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			width: 50%;
			position: relative;
			float: left;
			padding: 15px;
			margin: 0px;

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 15px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 3px;
						top: 5px;
						color: #fff;
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.rent-rate {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}


			}


		}


	}


	.search-result-properties {
		display: inline-block;

		>div {
			float: left;
			text-align: center;
			padding: 3px;

			border-right: 1px solid #ccc;

			:last-child {
				font-weight: bold;
			}
		}

		>div:last-child {
			border: none;
		}

	}



	.search-bar {
		padding: 5px 10px;

		.daterange {
			display: inline-block;
		}

		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:768px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;
			height: auto;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}


	.map-pane {
		@mixin flex;
		height: 95vh;

		@media (max-width:992px) {
			z-index: 50;
			padding: 0;

		}
	}

	.map-pane {
		&.full-screen {
			@media (max-width:989px) {
				position: fixed;
				top: 0;
				left: 0px;
				right: 0;
				bottom: 0;
				background: #fff;
				padding-top: 80px;
				height: 100vh;

				.header {
					display: block;
				}
			}

		}
	}




	@media (min-width:768px) {

		.map-pane {
			@mixin flex;

		}

		.result-pane {


			.more-btn-panel {
				display: none;
			}


		}


		.results {
			.srow {
				@mixin flexlayout row;

				.info-col {
					width: 60%;
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						position: absolute;
						@mixin size100;


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}



	}





}